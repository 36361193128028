import styled from "@emotion/styled";
import {
  BgHolesContainer,
  BgHole,
} from "assets/images";

export const HolesContainer = styled.div`
  user-select: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  flex-direction: column;
  .strike-popup {
    position: absolute;
    top: -55px;
    z-index: 1;
  }
  .holes {
    width: 300px;
    height: 55px;
    padding-left: 15px;
    background: url(${BgHolesContainer});
    background-size: 100% 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    position: relative;
    animation: holes ${(props) => (props.animate ? 1 : 0)}s infinite;
    animation-timing-function: ease-in-out;
    @keyframes holes {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
    .holes-text {
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
      font-family: Roboto;
      font-size: 25.5px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
    .ant-space {
      position: absolute;
      right: 15px;
      display: flex;
      .hole {
        width: 33px;
        height: 33px;
        background: url(${BgHole});
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          font-size: 32px;
          font-weight: bold;
          color: #fd3435;
        }
      }
    }
  }
  .holes-mask {
    width: 100%;
    position: absolute;
    top: -40px;
    z-index: 1;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 10px;
    border: solid #a029ca 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    .mask-strike-box-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 15px;
      .mask-strike-box {
        width: 20px;
        height: 15px;
        border: solid 2px white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;